import { Storage } from "../../domain/extensions/storage";

export default class MemoryStorage implements Storage {

    public name: string = "memorystorage";

    private storage: {[key: string]: any} = {}

    public get(key: string): any {
        return this.storage[key]
    }

    public set(key: string, value: any): boolean {
        this.storage[key] = value
        return true
    }

    public remove(key: string): void {
        delete this.storage[key]
    }

    public has(key: string): boolean {
        return this.storage.hasOwnProperty(key);
    }

    public clear(): void {
        this.storage = {}
    }

}
