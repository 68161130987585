/**
 * Size interface
 */
export interface Size {
    /** Width */
    width: number;
    /** Height */
    height: number;
}

/**
 * Size breakpoints.
 * Contains all breakpoints used for resize callback.
 */
export enum Breakpoint {
    Desktop = "desktop",
    Tablet = "tablet",
    Mobile = "mobile",
}
