import { Logger } from "../../domain/extensions/logger";

declare var console: any;
declare var process: any;

export default class ConsoleLogger implements Logger {

    public name: string = "console";

    public debug(message: string, context?: any): void {
        if (process.env.NODE_ENV !== "production") {
            console.debug(this.parse("DEBUG", message, context), context);
        }
    }

    public info(message: string, context?: any): void {
        if (process.env.NODE_ENV !== "production") {
            console.info(this.parse("INFO", message, context), context);
        }
    }

    public warn(message: string, context?: any): void {
        console.warn(this.parse("WARN", message, context), context);
    }

    public error(message: string, exception?: Error, context?: any): void {
        console.error(this.parse("ERROR", message, context), context, exception);
    }

    /**
     *
     * @param level Log level
     * @param message Message
     * @param context Any context
     */
    private parse(level: "DEBUG" | "INFO" | "WARN" | "ERROR", message: string, context?: any) {
        return `${level}: ${message}`;
    }

}
