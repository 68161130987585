import React from "react";
import { SizeContext, SizeContextContent } from "./context";

declare var process: any;

export interface WindowSizeProps {
    windowSize: SizeContextContent
}

export interface WithWindowSizeProps extends WindowSizeProps { }

export const withWindowSize = <P extends WindowSizeProps>(WrappedComponent: React.ComponentType<P>) => {

    const WindowSizesComponent: React.FC<Omit<P, keyof WindowSizeProps>> = (props) => {
        return (
            <SizeContext.Consumer>
                {(value) => <WrappedComponent {...props as any} windowSize={value} />}
            </SizeContext.Consumer>
        );
    }

    if (process.env.NODE_ENV !== "production") {
        WindowSizesComponent.displayName = `withWindowSize(${WrappedComponent.displayName || WrappedComponent.name})`
    }

    return WindowSizesComponent
}
