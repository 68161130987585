import { DOM } from "../../domain/extensions/dom";

declare var window: any;

export default class WebWindow implements DOM {

    public name = "window";

    public onScrollChange(callback: (event: Event) => void, container?: any): () => void {
        // Bind default container
        container = container || this.getGlobal();
        // Create wrapper
        const wrapper = (event: Event) => {
            callback(event);
        };
        // Install listener
        this.addEventListener(container, "scroll", wrapper);
        return () => {
            this.removeEventListener(container!, "scroll", wrapper);
        }
    }

    public getGlobal(): any {
        return window;
    }

    public getDocument(): any {
        return window.document;
    }

    public isClientSide(): boolean {
        return !!this.getGlobal();
    }

    public setTimeout(callback: () => void, interval: number): () => void {
        const id = window.setTimeout(callback, interval);
        return () => {
            window.clearTimeout(id)
        }
    }

    public setInterval(callback: () => void, interval: number): () => void {
        const id = window.setInterval(callback, interval);
        return () => {
            window.clearInterval(id)
        }
    }

    public scrollTo(x: number = 0, y: number = 0): void {
        const global = this.getGlobal()
        if (global) {
            global.scrollTo(x, y)
        }
    }

    public appendScript(id: string, src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const document = this.getDocument()

            if (document) {
                const script = document.createElement("script")
                script.id = id
                script.type = "text/javascript"
                script.async = "async"
                script.defer = "defer"
                script.src = src
                script.onload = resolve
                script.onerror = reject
                document.body.appendChild(script)
            }
        });
    }

    public appendStylesheet(id: string, src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const document = this.getDocument()

            if (document) {
                const script = document.createElement("link")
                script.id = id
                script.rel = "stylesheet"
                script.href = src
                script.onload = resolve
                script.onerror = reject
                document.head.appendChild(script)
            }
        });
    }

    //#region Private methods

    private addEventListener(container: any, event: string, callback: any): void {
        container.addEventListener(event, callback);
    }

    private removeEventListener(container: any, event: string, callback: any): void {
        container.removeEventListener(event, callback);
    }

    //#endregion

}
