import React from "react";
import { PureComponent } from "../../base";
import { RouterOutlet } from "./routerOutlet";

export interface Props {
    /** Route ID for RouterOutlet usage */
    id?: string
}

export class DefaultRouteComponent extends PureComponent<Props> {

    public render() {
        return (
            <RouterOutlet id={this.props.id} />
        )
    }

}
