import { Storage } from "../../domain/extensions/storage";

declare var window: any;

export default class SessionStorage implements Storage {

    public name: string = "sessionstorage";

    public get(key: string): any {
        try {
            return window.sessionStorage.getItem(key);
        } catch (error) {
            // This should not happen
            // maybe this was blocked by security issues
            // In that case, just return undefined
            return undefined;
        }
    }

    public set(key: string, value: any): boolean {
        try {
            window.sessionStorage.setItem(key, value);
            return true;
        } catch (error) {
            return false;
        }
    }

    public remove(key: string): void {
        window.sessionStorage.removeItem(key);
    }

    public has(key: string): boolean {
        return this.get(key) != null;
    }

    public clear(): void {
        window.sessionStorage.clear();
    }

}
