import { Action } from "../store";
import { Generic } from "../generic";
import * as Store from "../store";
import { Framework } from "../framework";
import { DloOutput, DloInput } from "../../extensions/dlo";

/**
 * Default Action processor.
 * This processor will dispatch the following events:
 * - <base_key>_START
 * - <base_key>_SUCCESS
 * - <base_key>_ERROR
 *
 * The given request is going to be executed AS-IS.
 *
 * @param key Action key base
 * @param request Request to execute or callback function to create the request
 */
export const actionDefaultProcessor = <Output extends DloOutput = DloOutput<any>, State = any, ActionType extends Action = Action>(key: string, request: DloInput | ((getState: Store.StoreGetter<State>) => DloInput), context?: any): Store.ThunkAction<Promise<Output["body"]>, State, any, Action> => {
    return async (dispatch, getState) => {
        // Dispatch START action
        dispatch({
            type: key + "_START",
            context,
        } as any)

        // Execute request
        try {
            const response = (await actionRequestProcessor(typeof request === "function" ? request(getState) : request)) as Output
            dispatch({
                type: key + "_SUCCESS",
                payload: response.body || response,
                metadata: response.headers,
                context,
            })
            return (response.body || response)
        } catch (error) {
            dispatch({
                type: key + "_ERROR",
                payload: error,
                context,
            })
            throw error
        }
    }
}

/**
 * Request Action processor.
 * This processor only calls the given requests.
 * @param request Request to execute
 */
export const actionRequestProcessor = async (request: DloInput): Promise<DloOutput> => {
    const generic = new Generic()
    return (generic.framework as Framework).dlo.call(request)
}

/**
 * Default reducer options
 */
export interface ReducerDefaultProcessorOptions<StateType, ActionType> {
    /**
     * Clears the node data on START event.
     * This will make loaded false and data undefined.
     * @default false
     */
    clearOnStart?: boolean
    /**
     * Processor function before saving to data node.
     * Data returned by this method will be saved AS-IS.
     * @param data Data payload
     */
    processor?: (data: any) => any
    /**
     * Fallback method for when nothing is found
     */
    fallback?: (state: StateType, action: ActionType) => any
}

export const reducerDefaultProcessorKeyStart    = "_START"
export const reducerDefaultProcessorKeySuccess  = "_SUCCESS"
export const reducerDefaultProcessorKeyError    = "_ERROR"
export const reducerDefaultProcessorKeyReset    = "_RESET"

/**
 * Default Reducer processor.
 * This processor updates the node state for the following events:
 * - <base_key>_START
 * - <base_key>_SUCCESS
 * - <base_key>_ERROR
 * - <base_key>_RESET
 *
 * @param key Action key base
 * @param state Current state
 * @param action Action
 * @param options Processor options
 */
export const reducerDefaultProcessor = <StateType extends object, ActionType extends Action>(key: string, state: StateType, action: ActionType, options: ReducerDefaultProcessorOptions<StateType, ActionType> = {}): StateType => {
    switch (action.type) {
        case (key + reducerDefaultProcessorKeyStart):
            if (options.clearOnStart) {
                return {
                    ...(state as object),
                    lastUpdate: new Date(),
                    context: (action as any).context || (state as any).context,
                    loading: true,
                    loaded: false,
                    data: undefined,
                    error: undefined,
                    metadata: undefined,
                } as StateType
            }
            return {
                ...(state as object),
                lastUpdate: new Date(),
                context: (action as any).context || (state as any).context,
                loading: true,
                error: undefined,
            } as StateType
        case (key + reducerDefaultProcessorKeySuccess):
            return {
                ...(state as object),
                lastUpdate: new Date(),
                context: (action as any).context || (state as any).context,
                loading: false,
                loaded: true,
                data: options.processor ? options.processor((action as any).payload) : (action as any).payload,
                metadata: (action as any).meta,
            } as StateType
        case (key + reducerDefaultProcessorKeyError):
            return {
                ...(state as object),
                lastUpdate: new Date(),
                context: (action as any).context || (state as any).context,
                loading: false,
                loaded: true,
                error: (action as any).payload.body,
            } as StateType
        case (key + reducerDefaultProcessorKeyReset):
            return {
                ...(state as object),
                lastUpdate: new Date(),
                context: (action as any).context || (state as any).context,
                loading: false,
                loaded: false,
                data: undefined,
                metadata: undefined,
                error: undefined,
            } as StateType
        default:
            if (options.fallback && typeof options.fallback === "function") {
                return options.fallback(state, action) || state
            }
    }

    return state
}
