import React, { createContext, useState, useEffect } from "react"
import { useFramework } from "../components/useFramework"

export interface I18nContextValue {
    lastUpdate: Date
}

export const I18nContext = createContext<I18nContextValue>({ lastUpdate: new Date() })

export const I18nProvider = (props: any) => {
    const [value, setValue] = useState<I18nContextValue>({ lastUpdate: new Date() })
    const framework = useFramework()

    useEffect(() => {
        const subscription = framework.i18n.onCatalogChange(() => setValue({ lastUpdate: new Date() }))

        return () => {
            subscription()
        }
    }, [setValue])

    return (
        <I18nContext.Provider value={value}>
            {props.children}
        </I18nContext.Provider>
    )
}
