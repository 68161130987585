import { Component as ComponentInterface} from "../domain/components/component";
import { Framework } from "../domain/components/framework";
import { ground } from "../ground";

export class Component implements ComponentInterface {

    public framework: Framework;
    public fw: Framework;

    constructor() {
        this.framework = this.fw = {
            config: ground.config,
            ...ground.extensions as any,
        };
    }
}
