import { Events, EventCallback } from "../../domain/extensions/events";
import { Logger } from "../../domain/extensions/logger";
import { Config } from "../../domain/config";
import { Ground } from "../../ground";

declare var setTimeout: any

export default class EventsManager implements Events {

    public name: string = "events";

    //#region Private properties

    /** Contexts storage */
    private contexts: Map<string | symbol, EventCallback[]> = new Map()

    /** Logger */
    private logger: Logger = undefined as any

    //#endregion

    public initialize(config: Config, app: Ground): void {
        // Do nothing
        this.logger = app.framework.log
    }

    public subscribe<T = any>(context: string | symbol, callback: EventCallback<T>): () => void {
        // Get context
        const persistedContext = this.tryGetAndCreate(context)

        // Push the subscriber
        persistedContext.push(callback)

        // Return the unsubscribe
        return () => {
            persistedContext.splice(persistedContext.indexOf(callback), 1)
        }
    }

    public publish<T = any>(context: string | symbol, value?: T, immediate?: boolean): void {
        // Get context
        const persistedContext = this.tryGetAndCreate(context)

        const execute = () => {
            // Iterate over all callbacks and call them
            persistedContext.forEach((callback) => {
                try {
                    callback(value)
                } catch (error) {
                    this.logger.warn(`Failed to call subscriber for context ${context.toString()}`)
                }
            })
        }

        if (immediate === true) {
            execute()
        } else {
            setTimeout(() => execute())
        }
    }

    //#region Private methods

    /**
     * Tries to get a context and create if not exists
     * @param context Context to search
     */
    private tryGetAndCreate(context: string | symbol): EventCallback[] {
        // Create a new context if it doesn't exist
        if (!this.contexts.has(context)) {
            this.contexts.set(context, [])
        }

        return this.contexts.get(context) || []
    }

    //#endregion
}
